import { Component } from '@angular/core';
import { NewsService } from '../../services/news.service';
import { Router } from '@angular/router';
import { UtagService } from '../../components/analytics';
import { LoginService } from '../../login';
import { BehaviorSubject, catchError, finalize, of, tap } from 'rxjs';
import { LoaderStatus } from '../../components/loader';

@Component({
  selector: 'mf-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent {

  public banners = this.newsService.get_all_news().pipe(
    tap(() => {
      this.hasErrorBanners = false;
      this.$loadingStatusBanners.next(LoaderStatus.loading)
    }),
    catchError(() => {
      this.hasErrorBanners = true;
      this.$loadingStatusBanners.next(LoaderStatus.error);
      return of(null)
    }),
    finalize(() => {
      !this.hasErrorBanners ? this.$loadingStatusBanners.next(LoaderStatus.success) : null;
    })
  );

  public news = this.newsService.get_akt_information().pipe(
    tap(() => {
      this.hasErrorNews = false;
      this.$loadingStatusNews.next(LoaderStatus.loading)
    }),
    catchError(() => {
      this.hasErrorNews = true;
      this.$loadingStatusNews.next(LoaderStatus.error);
      return of(null)
    }),
    finalize(() => {
      !this.hasErrorNews ? this.$loadingStatusNews.next(LoaderStatus.success) : null;
    })
  );
  
  public empfehlungen = this.newsService.get_highlights_information().pipe(
    tap(() => {
      this.hasErrorEmpfehlungen = false;
      this.$loadingStatusEmpfehlungen.next(LoaderStatus.loading)
    }),
    catchError(() => {
      this.hasErrorEmpfehlungen = true;
      this.$loadingStatusEmpfehlungen.next(LoaderStatus.error);
      return of(null)
    }),
    finalize(() => {
      !this.hasErrorEmpfehlungen ? this.$loadingStatusEmpfehlungen.next(LoaderStatus.success) : null;
    })
  );

  readonly isLoggedIn = this.loginService.isLoggedIn
  $loadingStatusBanners = new BehaviorSubject<LoaderStatus>(LoaderStatus.loading);
  private hasErrorBanners: boolean = false;
  $loadingStatusNews = new BehaviorSubject<LoaderStatus>(LoaderStatus.loading);
  private hasErrorNews: boolean = false;
  $loadingStatusEmpfehlungen = new BehaviorSubject<LoaderStatus>(LoaderStatus.loading);
  private hasErrorEmpfehlungen: boolean = false;

  constructor(
    private newsService: NewsService,
    private router: Router,
    private utag: UtagService,
    private loginService: LoginService
  ) {
  }

  startSearch(query:string) {
    this.utag.aktion('content', 'button-image', "suche");
    this.router.navigate(['/promotions'], { queryParams: { query } });
  }

  newsSlideChange(slideIndex: number) {
    this.utag.aktion("content", "button-image", "banner_" + (slideIndex + 1));
  }
}
