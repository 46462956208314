export interface Promotion {
  id: string;
  fp_id_intern: string;
  name: string,
  foerderart: string;
  foerderberechtigte: string;
  foerderbereich: string;
  foerdergebiet: string;
  foerdergeber: string;
  description: string;
  keywords: string;
  ansprechpunkte: string;
  twords: string;
  t_produkte?: Product[],
  favoriten?: boolean,
  url: string,
  kurztext_simple: string
  lastCrawledDate: string
}

export interface Product {
  id: string,
  t_produkt: string,
  tp_url: string,
  twords: string,
}
