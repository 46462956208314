import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PromotionService } from '../../services/promotion.service';
import { Observable, ReplaySubject, catchError, map, of, startWith, switchMap, tap, withLatestFrom } from 'rxjs';
import { Promotion } from '../../model';
import { GeneralAdvice } from '../../../../shared/general_advice';
import { ContactService } from '../../../../services/contact.service';
import { LoginService } from '../../../../login';
import { FavoriteService } from '../../services';

@Component({
  selector: 'mf-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.scss']
})
export class PromotionDetailsComponent {

  public promotion : Observable<Promotion>;
  public contactInfo : Observable<GeneralAdvice> = this.contactService.getGeneralAdvice();
  public isLoggedIn: Observable<Boolean> = this.loginService.isLoggedIn
  public isFavChecked: Observable<boolean>
  public $error = new ReplaySubject<any>(1);

  constructor(
    private route: ActivatedRoute,
    private promotionService: PromotionService,
    private favoriteService: FavoriteService,
    private contactService: ContactService,
    private loginService: LoginService
  ) {
    const promotionId = this.route.params.pipe(
      map<Params, string>(params => params['id'])
    )

    this.promotion = promotionId.pipe(
      tap(() => this.$error.next(undefined)),
      switchMap(id => this.promotionService.getPromotionById(id).pipe(
        catchError(error => {
          this.$error.next(error);
          return of(null)
        }),
      ))
    );

    this.isFavChecked = this.favoriteService.get().pipe(
      withLatestFrom(promotionId),
      tap(favs => console.log(favs)),
      map(([favorites, id]) => favorites.has(id)),
      startWith(false)
    )
  }

  public toggleFavorite(id: string, value: boolean) {
    value ? this.favoriteService.add(id) : this.favoriteService.remove(id)
  }

  public share_link(){
    var link = window.location.href

    if (navigator.share) {
      navigator.share({
        title: 'Meine Förderung',
        text: 'Hier der Link zum Förderprogramm:',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

}
