<mf-animated-header>
  <mf-search-input label="Thema, Bereich, Bundesland eingeben" (searchValue)="startSearch($event)"></mf-search-input>
</mf-animated-header>


<div class="scroll-container" mfScrollShadow>
  <div class="container" #contentArea>
    <div class="row m-t-16" >
      <div class="col-12">
        <mf-carousel *ngIf="banners | async as data; else carouselLoaderOrError" [data]="data"></mf-carousel>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">

        <mf-app-store-card></mf-app-store-card>
        <mf-login-card></mf-login-card>

        <mat-card class="m-b-8 mf-card">
          <mat-card-header>
              <mat-card-title class="mf-card-icon-title">
                <mat-icon svgIcon="mf-quick-check"></mat-icon>
                Telekom Fördermittel Quick-Check
              </mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <p>
                  Mit unserem Fördermittel Quick-Check, ermitteln
                  Sie mit wenigen Klicks Ihre Förderchancen auf staatliche Zuschüsse für Ihre Digitalisierung.
              </p>
          </mat-card-content>
          <mat-card-actions class="flex-center">
            <a role="link" [routerLink]="['/quickcheck']" [queryParams]="{skipIntro: true}" mat-flat-button color="primary" class="medium uppercase" [trackClick]="['content', 'button', 'jetzt-foerderfaehigkeit-pruefen']">
              Jetzt Förderfähigkeit prüfen
            </a>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-12 col-lg-6">
        <mat-card class="m-b-8" *ngIf="(news | async) as newsItems; else newsLoaderOrError">
          <mat-card-header>
              <mat-card-title>Aktuelle Informationen</mat-card-title>
          </mat-card-header>
          <mat-card-content>
              <div *ngFor="let news of newsItems">
                  <p [innerHTML]="news.neuigkeit | secureHTML" mfDynamicContentLink></p>
              </div>
          </mat-card-content>
      </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="(empfehlungen | async) as empfehlungenItems; else empfehlungsLoaderOrError ">
          <ng-container *ngIf="empfehlungenItems.length > 0">
            <h2 class="m-t-16">Unsere Empfehlungen</h2>
            <mf-horizontal-scroller class="m-b-16">
              <mf-empfehlungs-card *ngFor="let element of empfehlungenItems" [data]="element"></mf-empfehlungs-card>
            </mf-horizontal-scroller>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #carouselLoaderOrError>
  <ng-container *ngIf="($loadingStatusBanners | async) === 'loading'; else error">
    <mf-skeleton-loader height="250px"></mf-skeleton-loader>
    <div class="row justify-content-center m-b-16 m-t-8">
      <div class="col-5 col-md-2">
        <span role="status" class="cdk-visually-hidden">Lade Karusellinhalte</span>
        <mf-skeleton-loader height="15px"></mf-skeleton-loader>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #newsLoaderOrError>
  <ng-container *ngIf="($loadingStatusNews | async) === 'loading'; else error">
    <span role="status" class="cdk-visually-hidden">Lade die neuesten Neuigkeiten</span>
    <mf-skeleton-loader height="250px"></mf-skeleton-loader>
  </ng-container>
</ng-template>

<ng-template #empfehlungsLoaderOrError>
  <ng-container *ngIf="($loadingStatusEmpfehlungen | async) === 'loading'; else error">
    <h2 class="m-t-16">Unsere Empfehlungen</h2>
    <mf-horizontal-scroller class="m-b-16">
      <span role="status" class="cdk-visually-hidden">Lade Empfehlungen</span>
      <mf-skeleton-loader *repeatTimes="4" height="265px" width="350px"></mf-skeleton-loader>
    </mf-horizontal-scroller>
  </ng-container>
</ng-template>

<ng-template #error>
  <mf-loader-error class="block m-b-24 m-t-8"></mf-loader-error>
</ng-template>

